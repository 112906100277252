<template>
    <div v-if="(memod && memod.id) || isPreviewMode" class="memod-reader">
        <report-modal @reported-entity="handleReportedEntity" />
        <report-thanks-modal />
        <stores-modal />
        <mobile-download-banner class="d-block d-lg-none" />
        <template>
            <router-link v-if="isLoggedIn" :to="{ name: 'landing' }" class="back-button-to-explore">
                <img src="@assets/img/icons/left-chevron.svg" width="10px">
            </router-link>
            <side-bar v-else />
        </template>

        <div class="container main-content">
            <div class="visual-touch-guide" />
            <div class="row">
                <div class="col-12 col-lg" :class="{ 'col-lg-9 ml-auto mr-auto' : memod.similar && !memod.similar.length }">
                    <div class="memod-content">
                        <div v-if="coverImage" class="row">
                            <div class="col">
                                <memo-images-carousel
                                    :memo-images="memoImagesForCarousel"
                                    @mark-cover-image="(filesystemId) => $emit('mark-cover-image', filesystemId)"
                                    @delete-image="(filesystemId) => $emit('delete-image', filesystemId)"
                                />
                            </div>
                        </div>
                        <div class="row author-and-memo-actions">
                            <div class="col col-sm-auto d-flex align-items-center">
                                <user-picture-name-nickname :user="memod.writer" class="author" />
                            </div>
                            <div class="col d-flex align-items-center justify-content-end">
                                <follow-user-button :user-to-follow="memod.writer" @toggled-follow="(is_following) => memod.writer.is_following = is_following" />
                                <actions-buttons
                                    :memod="memod"
                                    @toggled-like="toggleMemodLike"
                                    @toggled-save="(is_saved) => memod.is_saved = is_saved"
                                />
                                <div v-if="!isMemodAuthor" class="more-options">
                                    <v-popover>
                                        <div class="tooltip-target b3">
                                            <img src="@assets/img/icons/three-dots.svg" alt="More options">
                                        </div>
                                        <template slot="popover">
                                            <div class="popover-options">
                                                <report-button :entity-id="Number(memod.id)" entity-name="memo" class="popover-option" />
                                            </div>
                                        </template>
                                    </v-popover>
                                </div>
                            </div>
                        </div>
                        <template v-if="!isSingleMemod">
                            <hr>
                            <h1 class="h0 memo-title">
                                {{ memod.title }}
                            </h1>
                            <hr>
                        </template>
                        <serialized-pagination
                            v-if="!isSingleMemod"
                            :memod="memod"
                            :current-part-number="currentPartNumber"
                            @page-changed="toggleAnimation"
                        />
                        <div class="current-thread-part">
                            <h1 class="sub-heading">
                                {{ currentPart.title }}
                            </h1>
                            <pretty-date v-if="memod.published_at" :date="memod.published_at.replace(/ /g,'T')" />
                            <bullets
                                :memo-id="Number(memod.id)"
                                :bullets="currentPart.bullets"
                                :bullets-display-type="memod.display_type.name"
                                @read-bullet="handleReadBullet"
                            />
                        </div>
                        <div v-if="!memod.is_original" class="source-link">
                            <span>Source</span>
                            <a :href="memod.source_link" target="_blank">{{ memod.source_title }}</a>
                        </div>
                        <div class="topics">
                            <span>Topics</span>
                            <div class="topics-list">
                                <template v-for="topic in memod.topics">
                                    <topic-link :key="topic.id" :topic="topic" />
                                </template>
                            </div>
                        </div>
                        <span v-if="memod.location.name && memod.location.name != ''" class="location">
                            Location: {{ memod.location.name }}
                        </span>
                        <serialized-pagination
                            v-if="!isSingleMemod"
                            :memod="memod"
                            :current-part-number="currentPartNumber"
                            @page-changed="handlePageChange"
                        />
                        <content-recommendations :memo-id="Number(memod.id)" :creator-displayname="memod.writer.displayname" />
                        <transition name="fade">
                            <enjoying-memod v-if="!isLoggedIn" />
                        </transition>
                        <comments-list :memod-id="Number(this.$route.params.id)" :total-comments="Number(memod.total_comments)" />
                    </div>
                </div>
                <div v-if="memod.similar && memod.similar.length" class="col-12 col-lg-auto recommended">
                    <h2>Recommended for you</h2>
                    <mobile-recommended-carousel
                        class="d-block d-lg-none"
                        :similar-memos="memod.similar"
                        @toggled-like="(data, similarId) => toggleSimilarMemodLike(data, similarId)"
                        @toggled-save="(is_saved, similarId) => toggleSimilarMemodSave(is_saved, similarId)"
                    />
                    <div class="desktop-recommended d-none d-lg-block">
                        <template v-for="similarMemod in memod.similar">
                            <memod-card
                                :key="similarMemod.id"
                                :memod="similarMemod"
                                @toggled-like="(data) => toggleSimilarMemodLike(data, similarMemod.id)"
                                @toggled-save="(is_saved) => toggleSimilarMemodSave(is_saved, similarMemod.id)"
                            />
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <memod-footer v-if="memod && memod.id" />
    </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { truncate, sortBy } from "lodash";
import { MEMO_FILES_FIELDS_NAMES } from "@/utils/constants";
import { format as dateFnsFormat } from "date-fns";

export default {
    name: "MemodReader",
    metaInfo() {
        return {
            titleTemplate: this.memod.title,
            meta: [
                {
                    hid: "og:title",
                    property: "og:title",
                    content: `${this.memod.title}`,
                    vmid: "og:title"
                },
                {
                    hid: "twitter:title",
                    name: "twitter:title",
                    content: `${this.memod.title}`,
                    vmid: "twitter:title"
                },
                {
                    hid: "description",
                    property: "description",
                    content: this.memodDescription,
                    vmid: "description"
                },
                {
                    hid: "og:description",
                    property: "og:description",
                    content: this.memodDescription,
                    vmid: "og:description"
                },
                {
                    hid: "twitter:description",
                    name: "twitter:description",
                    content: this.memodDescription,
                    vmid: "twitter:description"
                },
                {
                    hid: "og:image",
                    property: "og:image",
                    content: this.coverImage.ogImage,
                    vmid: "og:image"
                },
                {
                    hid: "og:image:width",
                    property: "og:image:width",
                    content: "600",
                    vmid: "og:image:width"
                },
                {
                    hid: "og:image:height",
                    property: "og:image:height",
                    content: "600",
                    vmid: "og:image:height"
                },
                {
                    hid: "twitter:image",
                    name: "twitter:image",
                    content: this.coverImage.twitterImage,
                    vmid: "twitter:image"
                }
            ]
        }
    },
    components: {
        MobileDownloadBanner: () => import(/* webpackChunkName: "mobile-download-banner" */ "@c/molecules/mobile-download-banner"),
        memoImagesCarousel: () => import(/* webpackChunkName: "memo-images-carousel" */ "@c/organisms/memo-images-carousel"),
        MobileRecommendedCarousel: () => import(/* webpackChunkName: "mobile-recommended-carousel" */ "./mobile-recommended-carousel"),
        Bullets: () => import(/* webpackChunkName: "bullets" */ "./bullets/"),
        TopicLink: () => import(/* webpackChunkName: "tag" */ "@c/atoms/topic"),
        MemodCard: () => import(/* webpackChunkName: "memod-card" */ "@c/molecules/memod-card"),
        UserPictureNameNickname: () => import(/* webpackChunkName: "user-picture-name-nickname" */ "@c/molecules/user-picture-name-nickname"),
        FollowUserButton: () => import(/* webpackChunkName: "follow-user-button" */ "@c/atoms/follow-user-button"),
        PrettyDate: () => import(/* webpackChunkName: "pretty-date" */ "@c/atoms/pretty-date"),
        StoresModal: () => import(/* webpackChunkName: "stores-modal" */ "@c/organisms/modals/stores-modal"),
        ActionsButtons: () => import(/* webpackChunkName: "actions-buttons" */ "@c/molecules/actions-buttons"),
        MemodFooter: () => import(/* webpackChunkName: "memod-footer" */ "@c/organisms/footer"),
        SerializedPagination: () => import(/* webpackChunkName: "serialized-pagination" */ "./serialized-pagination"),
        ContentRecommendations: () => import(/* webpackChunkName: "content-recommendations" */ "./content-recommendations/"),
        CommentsList: () => import(/* webpackChunkName: "comments-list" */ "./comments/"),
        EnjoyingMemod: () => import(/* webpackChunkName: "enjoying-memod" */ "@c/molecules/enjoying-memod"),
        SideBar: () => import(/* webpackChunkName: "side-bar" */ "@c/organisms/side-bar"),
        ReportButton: () => import(/* webpackChunkName: "report-button" */ "@c/molecules/report/report-button"),
        ReportModal: () => import(/* webpackChunkName: "report-modal" */ "@/components/molecules/report/report-modal.vue"),
        ReportThanksModal: () => import(/* webpackChunkName: "report-thanks-modal" */ "@/components/molecules/report/thanks-modal.vue")
    },
    props: {
        isPreviewMode: {
            type: Boolean,
            default: false
        },
        memoPreview: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            imageResizerUrl: process.env.VUE_APP_IMAGE_RESIZER_URL,
            memod: {
                files: [],
                type: { id: 1, name: "Single" },
                display_type: { id: "1", name: "bullet" },
                source_link: "",
                title: "",
                source_title: "",
                topics: [
                    {
                        name: "saving"
                    }
                ],
                parts: [
                    {
                        title: "",
                        bullets: [
                            {
                                prettyText:"<p><br></p>",
                                rawText: ""
                            }
                        ]
                    }
                ],
                is_original: 0,
                is_published: 0
            },
            comments: []
        };
    },
    computed: {
        ...mapGetters({
            isLoggedIn: "User/isLoggedIn"
        }),
        ...mapState({
            userData: state => state.User.data
        }),
        isSingleMemod() {
            return this.memod.type.name == "Single";
        },
        isMemodAuthor() {
            if (this.memod.writer) {
                return Boolean(Number(this.memod.writer.id) === Number(this.userData.id));
            }
            return false;
        },
        currentPart() {
            if (this.isSingleMemod) {
                return this.memod.parts[0];
            }

            return this.$route.params.partNumber ? this.memod.parts[this.$route.params.partNumber - 1] : this.memod.parts[0];
        },
        currentPartNumber() {
            return Number(this.$route.params.partNumber ? this.$route.params.partNumber : 1);
        },
        memoImagesForCarousel() {
            const images = this.memod.files.filter((file) => (file.field_name !== MEMO_FILES_FIELDS_NAMES.BULLET_IMAGE) && (file.field_name !== MEMO_FILES_FIELDS_NAMES.THUMBNAIL_IMAGE));
            const sortedImages = sortBy(images, (i) => (i.field_name === MEMO_FILES_FIELDS_NAMES.COVER_IMAGE ? 0 : 1));
            const carouselMaxWidth = 650;

            return sortedImages.map(image => {
                return {
                    field_name: image.field_name,
                    filesystem_id: image.filesystem_id,
                    x1: `${this.imageResizerUrl}/${image.attributes.unique_name}?w=${carouselMaxWidth * 1}&fit=outside`,
                    x2: `${this.imageResizerUrl}/${image.attributes.unique_name}?w=${carouselMaxWidth * 2}&fit=outside`,
                    x3: `${this.imageResizerUrl}/${image.attributes.unique_name}?w=${carouselMaxWidth * 3}&fit=outside`
                }
            })
        },
        coverImage() {
            const coverImage = this.memod.files.find(file => file.field_name == "cover-image");

            if (!coverImage) {
                return false;
            }

            const isPortraitCover = coverImage.attributes.orientation == "portrait";

            const imagesSizes = {
                x1: "",
                x2: "",
                x3: ""
            }

            Object.keys(imagesSizes).forEach(key => {
                imagesSizes[key] = `${this.imageResizerUrl}/${coverImage ? coverImage.attributes.unique_name : ""}?w=${(isPortraitCover ? 380 : 600) * key.charAt(key.length - 1)}&fit=outside`
            })

            const formattedCover = {
                ...imagesSizes,
                ogImage:`${this.imageResizerUrl}/${coverImage.attributes.unique_name}?w=600&h=600`,
                twitterImage: `${this.imageResizerUrl}/${coverImage.attributes.unique_name}?w=1200&h=600`,
                attributes: coverImage.attributes
            }

            return formattedCover;
        },
        memodDescription() {
            return this.memod.parts[0] ? `${truncate(this.memod.parts[0].bullets[0].rawText, { "length": 150, "separator": /[^a-z0-9]? +/i })}` : "";
        },
        isSourceUrl() {
            if (this.memod.source_link.includes("memod.com") || this.memod.source_link.length == 0) {
                return false;
            }
            try {
                new URL(this.memod.source_link);
            } catch (_) {
                return false;
            }
            return true;
        }
    },
    watch: {
        "$route.params": {
            immediate: true,
            handler(newRoute, oldRoute) {
                if (this.$route.params.id && (Number(newRoute?.id) != Number(oldRoute?.id)) && !this.isPreviewMode) {
                    this.fetchMemod(this.$route.params.id)
                }
            }
        }
    },
    created() {
        if (this.isPreviewMode) {
            this.$emit("is-loading", true);
            this.setupPreviewMode();
        }
    },
    methods: {
        fetchMemod(memodId) {
            this.$emit("is-loading", true);
            axiosV2.get(`/memos/${memodId}`).then(({ data }) => {
                this.memod = data;
                this.$amplitude.logEvent("Reading", { event_name: "reading", event_type_id: 9, entity_id: data.id, title: data.title });
            }).catch((error) => {
                console.log(error.response.data.message);
                this.$router.push({
                    name: "404"
                });
            }).finally(() => {
                this.$emit("is-loading", false);
            });
        },
        handlePageChange() {
            this.toggleAnimation();
            const el = document.querySelector(".sub-heading");
            el.scrollIntoView();
        },
        toggleAnimation() {
            const currentPart = document.querySelector(".current-thread-part");
            currentPart.classList.add("animate");
            setTimeout(function() {
                currentPart.classList.remove("animate");
            }, 1000);
        },
        toggleMemodLike(data) {
            this.memod.is_liked = data.isLiked;
            this.memod.total_likes = data.totalLikes;
        },
        toggleSimilarMemodLike(data, memoId) {
            const likedMemo = this.memod.similar.findIndex(memo => memo.id == memoId);
            this.memod.similar[likedMemo].is_liked = data.isLiked;
            this.memod.similar[likedMemo].total_likes = data.totalLikes;
        },
        toggleSimilarMemodSave(isSaved, memoId) {
            const savedMemo = this.memod.similar.findIndex(memo => memo.id == memoId);
            this.memod.similar[savedMemo].is_saved = isSaved;
        },
        setupPreviewMode() {
            this.memod = this.memoPreview;
            if (!this.memoPreview.published_at) {
                this.memod.published_at = dateFnsFormat(new Date(), "yyyy-MM-dd HH:mm:ss");
            }
            this.memod.total_comments = 0;
            this.memod.writer = this.userData;

            setTimeout(() => {
                this.$emit("is-loading", false);
            }, 500);
        },
        handleReadBullet(readBullet) {
            if (!this.isPreviewMode) {
                const readBulletIndex = this.currentPart.bullets.findIndex(bullet => bullet.id == readBullet.bullet_id);
                this.currentPart.bullets[readBulletIndex].is_read = readBullet.is_read;
            }
        },
        handleReportedEntity(_id, name) {
            if (name === "memo") {
                this.$router.go(-1);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.memod-reader {
    overflow: hidden;

    .reader-carousel {
        @media(max-width: $sm) {
            z-index: 1;
            position: fixed;
            width: 100%;
        }
    }

    .back-button-to-explore {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        background-color: #2C2C2E;
        cursor: pointer;
        transition: background-color .3s;
        position: fixed;
        left: 15px;
        top: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 20;

        img {
            margin-right: 5px;
        }

        &:hover {
            background-color: lighten(#2C2C2E, 10%);
        }
    }

    .cover-image {
        border-radius: 20px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 40px;
        margin-left: auto;
        margin-right: auto;
    }

    .main-content {
        padding-top: 35px;
        margin-bottom: 120px;

        .memod-content {
            max-width: 650px;
            margin: 0 auto 50px auto;

            .current-thread-part.animate {
                animation: content-fade 1s;
            }

            hr {
                margin-top: 2rem;
                margin-bottom: 2rem;
                border: 1px solid rgba(84, 84, 88, 0.3);
            }

            .serialized-pagination {
                margin-top: 40px;
                margin-bottom: 40px;
            }

            .author-and-memo-actions {
                margin-bottom: 45px;

                .author {
                    text-decoration: none;
                    color: white;
                }

                .actions-buttons {
                    margin-left: 30px;
                }

                .more-options {
                    cursor: pointer;
                    margin-left: 30px;
                }
            }

            .memo-title {
                margin-bottom: 0;
                color: #D7D9DB;
            }

            /deep/ .pretty-date {
                margin-bottom: 60px;
            }

            .sub-heading {
                margin-bottom: 15px;

                span {
                    color: #656565;
                }
            }

            .source-link {
                @extend .body-text;
                margin-bottom: 20px;

                span {
                    display: block;
                    margin-right: 5px;
                    margin-bottom: 10px;
                }

                a {
                    display: block;
                    color: white;
                    background-color: #2C2C2E;
                    width: fit-content;
                    padding: 6px 10px;
                    border-radius: 4px;
                    text-transform: capitalize;
                    text-decoration: none;
                }
            }

            .topics {
                @extend .body-text;
                margin-bottom: 60px;

                span {
                    display: block;
                    margin-right: 5px;
                    margin-bottom: 10px;
                }

                .topics-list {
                    display: flex;

                    .topic {
                        margin-left: 10px;

                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
            }

            /* .topics {
                display: flex;
                flex-wrap: wrap;
                padding-left: 20px;
                margin-bottom: 25px;

                @media(max-width: $md) {
                    padding-left: 0;
                }

                /deep/ .tag {
                    margin-left: 15px;
                    margin-bottom: 15px;

                    @media(max-width: $md) {
                        margin-left: 5px;
                    }
                }
            } */

            .location {
                @extend .body-text;
                padding-left: 35px;
                margin-bottom: 35px;
                display: flex;
            }
        }

        .content-recommendations {
            margin-bottom: 60px;
        }

        .recommended {
            h2 {
                margin-bottom: 25px;

                @media(max-width: $md) {
                    font-size: 22px;
                }
            }

            /deep/ .memod-card {
                margin-bottom: 40px;
            }
        }

        .source-span {
            color: #05A88F;
        }
    }

    /deep/ .stores-links {
        background-size: 100%;
    }

    @keyframes content-fade {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }
}
</style>
